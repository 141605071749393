<template>
  <div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img
                    src="@/assets/images/logo-light.png"
                    alt=""
                    height="20"
                  />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Organisez votre entreprise avec élégance
              </p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Créer un compte</h5>
                  <p class="text-muted">C'est rapide et sécurisée!</p>
                </div>
                <div class="p-2 mt-4">
                  <form
                    class="needs-validation"
                    @submit.prevent="initRegister"
                  >
                    <b-alert
                      v-model="registerSuccess"
                      class="mt-3"
                      variant="success"
                      dismissible
                      >Inscription Reussie</b-alert
                    >

                    <b-alert
                      v-model="isRegisterError"
                      class="mt-3"
                      variant="danger"
                      dismissible
                      >{{ regError }}</b-alert
                    >

                    <div
                      v-if="false">
                      message
                    </div>
                    
                    <div class="mb-3">
                      <label for="firstname" class="form-label"
                        >Nom <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.firstname"
                        id="firstname"
                        :class="{
                          'is-invalid': v$.user.firstname.$error && user.firstname || v$.user.firstname.$error && submited,
                        }"
                        placeholder="Votre nom"
                      />
                      <div
                        v-for="(item, index) in v$.user.firstname.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.firstname || v$.user.firstname.$error && submited">{{ item.$message }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="lastname" class="form-label"
                        >Prénom <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.lastname"
                        :class="{
                          'is-invalid': v$.user.lastname.$error && user.lastname || v$.user.lastname.$error && submited,
                        }"
                        id="lastname"
                        placeholder="Votre prénom"
                      />
                      <div
                        v-for="(item, index) in v$.user.lastname.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.lastname || v$.user.lastname.$error && submited">{{ item.$message }}</span>
                      </div>

                    </div>
                    
                    <div class="mb-3">
                      <label for="gender" class="form-label"
                        >Genre <span class="text-danger">*</span></label
                      >
                      <v-select v-model="user.gender" :clearable="false" class="form-control mySelect"
                      :options="options" :label="label" 
                      :class="{ 'is-invalid': v$.user.gender.$error && user.gender || v$.user.gender.$error && submited,}" placeholder="Sélectionnez un genre"  />

                      <div
                        v-for="(item, index) in v$.user.gender.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.gender || v$.user.gender.$error && submited">{{ item.$message }}</span>
                      </div>
                    </div>
                    
                    <div class="mb-3">
                      <label for="number" class="form-label"
                        >Numéro <span class="text-danger">*</span></label
                      >
                      <input
                        type="tel"
                        class="form-control"
                        v-model="user.number"
                        id="number"
                        :class="{
                          'is-invalid': v$.user.number.$error && user.number || v$.user.number.$error && submited,
                        }"
                        placeholder="Votre numéro"
                      />
                      <div
                        v-for="(item, index) in v$.user.number.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.number || v$.user.number.$error && submited">{{ item.$message }}</span>
                      </div>
                    </div>
                    
                    <div class="mb-3">
                      <label for="email" class="form-label"
                        >Email <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.email"
                        id="email"
                        :class="{
                          'is-invalid': v$.user.email.$error && user.email || v$.user.email.$error && submited}"
                        placeholder="Votre addresse email"
                      />
                      <div
                        v-for="(item, index) in v$.user.email.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.email || v$.user.email.$error && submited">{{ item.$message }}</span>
                      </div>
                    </div>

                    <div class="mb-2">
                      <label for="userpassword" class="form-label"
                        >Mots de passe <span class="text-danger">*</span></label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                        type="password"
                        class="form-control pe-5"
                        v-model="user.password"
                        :class="{
                          'is-invalid': v$.user.password.$error && user.password || v$.user.password.$error && submited}"
                        id="password"
                        placeholder="Mots de passe"
                        name="password"
                      />
                        <button
                        @click="passwordToggle('password')"
                          class="
                            btn btn-link
                            position-absolute
                            end-0
                            top-0
                            text-decoration-none text-muted
                          "
                          type="button"
                        >
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                      <div
                        v-for="(item, index) in v$.user.password.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.password || v$.user.password.$error && submited">{{ item.$message }}</span>
                      </div>
                      </div>
                    </div>

                    <div class="mb-2">
                      <label for="password_confirmation" class="form-label"
                        >Retapez le mots de passe <span class="text-danger">*</span></label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                        type="password"
                        class="form-control pe-5"
                        v-model="user.password_confirmation"
                        :class="{
                          'is-invalid': user.password_confirmation != user.password && user.password_confirmation || user.password_confirmation != user.password && submited,
                        }"
                        id="passwordConfirm"
                        placeholder="Mots de passe"
                        name="password_confirmation"
                      />
                      <button
                      @click="passwordToggle('passwordConfirm')"
                          class="
                            btn btn-link
                            position-absolute
                            end-0
                            top-0
                            text-decoration-none text-muted
                          "
                          type="button"
                        >
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                      
                      <div v-if="user.password_confirmation && user.password_confirmation != user.password || user.password_confirmation != user.password && submited" class="invalid-feedback"
                      >
                        <span>Veuillez entrer les memes mots de passe !</span>
                      </div>

                    
                      </div>
                      </div>

                    <div class="mb-4">
                      <p class="mb-0 fs-12 text-muted fst-italic">
                        En vous Inscrivant vous acceptez nos 
                        <a
                          href="#"
                          class="
                            text-primary text-decoration-underline
                            fst-normal
                            fw-medium
                          "
                          >Conditions d'utilisation</a
                        >
                      </p>
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit">
                        Inscription
                      </button>
                    </div>

                    <div class="mt-4 text-center">
                      <div class="signin-other-title">
                        <h5 class="fs-13 mb-4 title text-muted">
                          S'inscrire avec
                        </h5>
                      </div>

                      <div>
                        <button
                          type="button"
                          class="
                            btn btn-primary btn-icon
                            waves-effect waves-light
                          "
                        >
                          <i class="ri-facebook-fill fs-16"></i>
                        </button>
                        <button
                          type="button"
                          class="
                            btn btn-danger btn-icon
                            waves-effect waves-light
                            ms-1
                          "
                        >
                          <i class="ri-google-fill fs-16"></i>
                        </button>
                        <button
                          type="button"
                          class="
                            btn btn-dark btn-icon
                            waves-effect waves-light
                            ms-1
                          "
                        >
                          <i class="ri-github-fill fs-16"></i>
                        </button>
                        <button
                          type="button"
                          class="
                            btn btn-info btn-icon
                            waves-effect waves-light
                            ms-1
                          "
                        >
                          <i class="ri-twitter-fill fs-16"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Déja inscrit ?
                <router-link
                  to="/login"
                  class="fw-semibold text-primary text-decoration-underline"
                >
                  Connexion
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} by EcoVlex
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import { required, email, minLength, maxLength, helpers } from "@vuelidate/validators";
  import { mapGetters } from 'vuex';
  import useVuelidate from "@vuelidate/core";
  import vSelect from 'vue-select'
  import 'vue-select/src/scss/vue-select.scss';

  const strongPass = helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/);
  
  
  export default {
    setup() {
      return { v$: useVuelidate() };
    },
    components: {
      "v-select": vSelect,
    },
    data() {
      return {
        user: {
          firstname: "john",
          lastname: "doe",
          number: "98652244",
          gender: "Homme",
          email: "doe@gmail.com",
          password: "P@ss2022",
          password_confirmation: "P@ss2022",
        },
        submited: false,
        regError: null,
        isRegisterError: false,
        registerSuccess: false,
        options: [
          { label: 'Homme', value: 'Homme' },
          { label: 'Femme', value: 'Femme' },
        ],
      };
    },
    validations: {
      user: {
        firstname: {
          required: helpers.withMessage("Veuillez entrer votre prenom", required),
          minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
          maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
          
        },
        lastname: {
          required: helpers.withMessage("Veuillez entrer votre nom", required),
          minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
          maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
        },
        number: {
          minLength: helpers.withMessage("8 characteres minimum", minLength(8)),
          required: helpers.withMessage("Veuillez entrer votre numéro", required),
        },
        gender: {
          required: helpers.withMessage("Veuillez selectionnez un genre", required),
        },
        email: {
          required: helpers.withMessage("Veuillez entrer votre email", required),
          email: helpers.withMessage("Veuillez entrer un email valide", email),
        },
        password: {
          required: helpers.withMessage("Veuillez entrer un mots de passe", required),
          minLength: helpers.withMessage("8 characteres minimum", minLength(8)),
          maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
          strongPass: helpers.withMessage("Au moins un chiffre , une majuscule et un charactere !@#$%^&*", strongPass)
        },
        password_confirmation: {
          required: helpers.withMessage("Veuillez confirmer votre mots de passe", required),
        },
      },
    },
    computed: {
      ...mapGetters(['helloWord']),
    },
    methods: {
      initRegister()
      {
        this.submited = true;
        if (this.v$.$error || this.user.password_confirmation != this.user.password )
        {
          console.log(this.user.password_confirmation == this.user.password);
          console.log("error");
          return false;
        } 
  
        this.register();
      },
      register()
      {
        console.log('starting register');
        const self = this;
        this.$store.dispatch('register',this.user)
        .then(
          function(response){
          console.log(response),
          self.$router.push("/login");
          },
          function(error){
            console.log(error);
          }
        )
     },
      passwordToggle(id)
      {
        var value = "password";
        var type = document.getElementById(id).getAttribute('type');

        if(type === value)
        value = "text";

        document.getElementById(id).setAttribute('type', value);
      },
    },
    mounted(){
      this.v$.user.$touch();
      console.log(this.helloWord);
    }
  };
  </script>